import { type Assistant, type Message } from '@langchain/langgraph-sdk';
import { useStream } from '@langchain/langgraph-sdk/react';
import { Link } from '@remix-run/react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { $path } from 'remix-routes';

import {
  type DtoGamePack,
  type DtoGamePackUGCFile,
} from '@lp-lib/api-service-client/public';

import { useLearningAnalytics } from '../../analytics/learning';
import { getFeatureQueryParamArray } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { getStaticAssetPath } from '../../utils/assets';
import { err2s } from '../../utils/common';
import { ThreadMessage } from '../Agent/Message';
import { GraphUtils, useLoadAssistant } from '../Agent/utils';
import { UGCFileManagerProvider } from '../Game/UGC';
import { useUGCFileManager } from '../Game/UGC/CustomGameFileManager';
import { CustomGamePackPromptEditor } from '../Game/UGC/CustomGamePackPromptEditor';
import { LunaParkLogo } from '../icons/LogoIcon';
import { ProvidersList } from '../ProvidersList';
import { useUser } from '../UserContext';
import { useOpenTrainingEditor } from './Editor/useOpenCloseTrainingEditor';
import { TrainingOutlineConfig } from './TrainingOutlineConfig';
import { Banner } from './TrainingStarter';
import { linkAgentThreadToGamePack, useCreateEmptyGamePack } from './utils';

type Props = {
  pack: DtoGamePack | null;
  files: DtoGamePackUGCFile[];
  prompt: string | null;
  starterOrigin: string | null;
  customTemplate: string | null;
  songId: string | null;
};

function Layout(props: { children: React.ReactNode }) {
  return (
    <div
      className='w-full h-full flex flex-col items-center'
      style={{
        background: `url('${getStaticAssetPath('images/ugc/background.png')}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {props.children}
    </div>
  );
}

function Header(props: { starterOrigin: string | null }) {
  let redirectLink = $path('/learning/admin');
  if (props.starterOrigin === 'landing') {
    redirectLink = $path('/learning/starter');
  }
  if (props.starterOrigin === 'pest-landing') {
    redirectLink = $path('/learning/pest-control');
  }
  return (
    <header
      className={`
              w-full flex-none h-14 bg-black border-b border-secondary 
              flex items-center justify-center text-white px-4 lg:px-7.5`}
    >
      <div className='flex items-center w-full h-full'>
        <div className='w-0 sm:w-1/3 h-full items-center gap-7.5 text-sms hidden sm:flex'>
          <Link to={redirectLink}>
            <LunaParkLogo className='w-27 h-7.5 mr-4' />
          </Link>
        </div>
        <div className='w-full sm:w-1/3 h-full flex items-center justify-center'>
          <p className='font-bold'>Creator Assistant</p>
        </div>
        <div className='w-0 sm:w-1/3 h-full'></div>
      </div>
    </header>
  );
}

type State = {
  messages: Message[];
  courseId: string | null;
  uid: string | null;
  config?: Record<string, unknown>;
  blockCraftInput: string;
};

function MessageList(props: { stream: ReturnType<typeof useStream<State>> }) {
  const { stream } = props;
  const ref = useRef<HTMLDivElement>(null);

  const messagesCount = stream.messages.length;
  const lastMessage = stream.messages[stream.messages.length - 1];

  const scrollToBottom = useLiveCallback(() => {
    if (!ref.current) return;
    const scrollHeight = ref.current.scrollHeight;
    const height = ref.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ref.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  });

  useLayoutEffect(() => scrollToBottom(), [scrollToBottom]);
  useLayoutEffect(
    () => scrollToBottom(),
    [
      messagesCount,
      scrollToBottom,
      lastMessage?.content?.length,
      stream.isLoading,
    ]
  );

  return (
    <div
      ref={ref}
      className='w-full flex-1 sm:w-160 overflow-y-auto scrollbar my-4'
    >
      <div className='w-full min-h-full flex-1 flex flex-col gap-2 justify-end text-white px-2.5'>
        {stream.messages.map((message, idx) => (
          <ThreadMessage
            key={message.id}
            message={message}
            showLoading={
              stream.isLoading &&
              message.type === 'ai' &&
              idx === stream.messages.length - 1
            }
          />
        ))}
        {stream.interrupt && (
          <button
            type='button'
            className='self-end btn-primary w-40 h-10'
            onClick={() => {
              stream.submit(undefined, { command: { resume: 'looks good' } });
            }}
            disabled={stream.isLoading}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
}

function PromptEditor(
  props: Props & {
    analytics: ReturnType<typeof useLearningAnalytics>;
    setShowZeroState: (show: boolean) => void;
    stream: ReturnType<typeof useStream<State>>;
    uid: string;
    threadId: string | null;
  }
) {
  const { files, prompt: initialPrompt, stream, threadId } = props;
  const fileman = useUGCFileManager();
  const createGamePack = useCreateEmptyGamePack(props.pack);
  const [outlineConfig, setOutlineConfig] = useState({
    customTemplate: props.customTemplate ?? '',
    songId: props.songId ?? null,
  });

  useLayoutEffect(() => {
    fileman.disable();
  }, [fileman]);

  useLayoutEffect(() => {
    if (!props.pack?.id) return;
    fileman.init(props.pack.id, files);
  }, [fileman, props.pack?.id, files]);

  const initFileman = useLiveCallback(async () => {
    if (fileman.inited) return;
    const pack = await createGamePack();
    if (!pack) return;
    if (pack.id !== props.pack?.id) {
    }
    fileman.init(pack.id, []);
  });

  useEffect(() => {
    fileman.uppy.addPreProcessor(initFileman);
    return () => fileman.uppy.removePreProcessor(initFileman);
  }, [fileman.uppy, initFileman]);

  const ensureThreadId = useLiveCallback(async (threadId: string) => {
    const pack = await createGamePack();
    if (!pack) return;
    linkAgentThreadToGamePack(pack, threadId);
  });

  useEffect(() => {
    if (!threadId) return;
    ensureThreadId(threadId);
  }, [threadId, ensureThreadId]);

  const ensureEverythingInited = useLiveCallback(async () => {
    const pack = await createGamePack();
    if (!pack) return { pack: null, inited: false };
    if (!fileman.inited) {
      fileman.init(pack.id, []);
    }
    return { pack, inited: true };
  });

  const sendMessage = useLiveCallback(async (message: string) => {
    const { pack, inited } = await ensureEverythingInited();
    if (!inited) return false;
    props.setShowZeroState(false);
    if (stream.interrupt) {
      stream.submit(undefined, {
        command: {
          resume: message,
          update: {
            config: {
              ...stream.values.config,
              customTemplate: outlineConfig.customTemplate.trim() || null,
              defaultSongId: outlineConfig.songId || null,
            },
          },
        },
        onDisconnect: 'continue',
      });
    } else {
      stream.submit(
        {
          courseId: pack?.id ?? null,
          uid: props.uid,
          messages: [{ type: 'human', content: message }],
          config: {
            humanAutoApproved: 'no',
            customTemplate: outlineConfig.customTemplate.trim() || null,
            defaultSongId: outlineConfig.songId || null,
          },
        },
        { onDisconnect: 'continue' }
      );
    }
  });

  useEffect(() => {
    if (!initialPrompt) return;
    sendMessage(initialPrompt);
  }, [initialPrompt, sendMessage]);

  const handleSubmit = useLiveCallback(async (message: string) => {
    sendMessage(message);
    return true;
  });

  return (
    <CustomGamePackPromptEditor
      enabled
      onSubmit={handleSubmit}
      onAbort={() => stream.stop()}
      isSubmitting={stream.isLoading}
      active
      autoFocus
      disableDeactivate
      placeholder='What training course are you building today?'
      wrapperClassName='mb-12'
      width='w-full sm:w-160 px-2.5'
      accessory={
        <TrainingOutlineConfig
          value={outlineConfig}
          onChange={setOutlineConfig}
        />
      }
    />
  );
}

function Main(
  props: Props & {
    assistant: Assistant;
  }
) {
  const { assistant } = props;
  const providers = [<UGCFileManagerProvider />];
  const analytics = useLearningAnalytics();
  const [showZeroState, setShowZeroState] = useState(!props.prompt);
  const openTrainingEditor = useOpenTrainingEditor();
  const user = useUser();
  const [threadId, setThreadId] = useState<string | null>(null);

  const stream = useStream<State>({
    apiUrl: GraphUtils.API_URL,
    assistantId: assistant.assistant_id,
    onThreadId: (threadId) => {
      setThreadId(threadId);
    },
    onFinish: async (state) => {
      if (state.next.length === 0 && state.tasks.length === 0) {
        if (!state.values.courseId) return;
        openTrainingEditor(state.values.courseId, {
          query: {
            agentic: getFeatureQueryParamArray('agentic'),
            from: 'outline',
          },
          // replace: true,
        });
      }
    },
  });

  return (
    <Layout>
      <Header starterOrigin={props.starterOrigin} />
      <div className='w-full h-[calc(100%-56px)] flex flex-col items-center justify-center'>
        <ProvidersList providers={providers}>
          {!showZeroState && <MessageList stream={stream} />}
          <div className='w-full flex flex-col gap-6'>
            {showZeroState && (
              <Banner
                dimWidth={0}
                tagline='instantly-create'
                widthClasses='sm:w-160 w-full px-2'
                videoCtrl='disabled'
              />
            )}
            <PromptEditor
              {...props}
              analytics={analytics}
              setShowZeroState={setShowZeroState}
              stream={stream}
              uid={user.id}
              threadId={threadId}
            />
          </div>
        </ProvidersList>
      </div>
    </Layout>
  );
}

export function TrainingOutlineAgent(props: Props) {
  const { data: assistant, error, isLoading } = useLoadAssistant('outline');

  if (isLoading) return null;
  if (error) {
    return (
      <div className='text-red-002'>
        Error loading assistant: {err2s(error)}
      </div>
    );
  }
  if (!assistant) {
    return (
      <div className='text-red-002'>Assistant not found: {err2s(error)}</div>
    );
  }

  return <Main {...props} assistant={assistant} />;
}
