import {
  type ClientLoaderFunctionArgs,
  type MetaFunction,
  useLoaderData,
} from '@remix-run/react';

import {
  type DtoFormResponse,
  type DtoGamePack,
  type DtoGamePackUGCFile,
} from '@lp-lib/api-service-client/public';

import { TrainingCreatorAssistant } from '../components/Training/TrainingCreatorAssistant';
import { TrainingOutlineAgent } from '../components/Training/TrainingOutlineAgent';
import {
  type FeatureQueryParamArrays,
  getFeatureQueryParamArrayWith,
} from '../hooks/useFeatureQueryParam';
import { apiService } from '../services/api-service';
import { LearningStarterLayout } from './learning.starter.client';

export async function clientLoader(action: ClientLoaderFunctionArgs): Promise<{
  pack: DtoGamePack | null;
  files: DtoGamePackUGCFile[];
  template: DtoGamePack | null;
  prompt: string | null;
  formResponse: DtoFormResponse | null;
  starterOrigin: string | null;
  agentic: FeatureQueryParamArrays['agentic'][number];
  customTemplate: string | null;
  songId: string | null;
}> {
  const url = new URL(action.request.url);
  const id = url.searchParams.get('id');
  const templateId = url.searchParams.get('template-id');
  const starterOrigin = url.searchParams.get('starter-origin');
  const template = templateId
    ? (await apiService.gamePack.getGamePackById(templateId)).data.gamePack
    : null;
  const formId = url.searchParams.get('form-id');
  const responseId = url.searchParams.get('response-id');
  const agentic = getFeatureQueryParamArrayWith('agentic', url.search);
  const customTemplate = url.searchParams.get('custom-template');
  const songId = url.searchParams.get('song-id');
  const formResponse =
    formId && responseId
      ? {
          formId,
          responseId,
        }
      : null;
  if (id) {
    const [pack, files] = await Promise.all([
      (await apiService.gamePack.getGamePackById(id)).data.gamePack,
      (await apiService.gamePack.getUGCFiles(id)).data.files,
    ]);
    return {
      pack,
      files,
      template,
      prompt: url.searchParams.get('prompt'),
      formResponse,
      starterOrigin,
      agentic,
      customTemplate,
      songId,
    };
  }
  return {
    pack: null,
    files: [],
    template,
    prompt: url.searchParams.get('prompt'),
    formResponse,
    starterOrigin,
    agentic,
    customTemplate,
    songId,
  };
}

export const meta: MetaFunction = () => {
  return [{ title: 'Training Creator Assistant | Luna Park' }];
};

export function Component() {
  const {
    pack,
    files,
    template,
    prompt,
    formResponse,
    starterOrigin,
    agentic,
    customTemplate,
    songId,
  } = useLoaderData<typeof clientLoader>();

  return (
    <LearningStarterLayout>
      {agentic !== 'disabled' ? (
        <TrainingOutlineAgent
          pack={pack}
          files={files}
          prompt={prompt}
          starterOrigin={starterOrigin}
          customTemplate={customTemplate}
          songId={songId}
        />
      ) : (
        <TrainingCreatorAssistant
          pack={pack}
          files={files}
          template={template}
          prompt={prompt}
          formResponse={formResponse}
          starterOrigin={starterOrigin}
        />
      )}
    </LearningStarterLayout>
  );
}
